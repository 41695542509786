export enum ReportType {
  transactions = "transactions",
  accounts = "accounts",
}

export enum ReportGranularity {
  account_level = "account_level",
  portfolio_level = "portfolio_level",
}

export type ReportRequest<T extends ReportType, P extends Record<string, any>> = {
  readonly report_type: T;
  report_parameters: P;
};

export type TransactionsReportRequest = ReportRequest<
  ReportType.transactions,
  {
    account_id?: string | number;
  }
>;

export type AccountsReportRequest = ReportRequest<ReportType.accounts, {}>;

export type DownloadableReports = TransactionsReportRequest | AccountsReportRequest;
